.App {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

.App h3{
    margin: 0;
    color: ivory;
}
.topping {
    margin-top: 0.3rem;
    vertical-align: text-bottom;
  }
  
.result {
    margin-top: 1rem;
  }

 
.toppingsList,
.total {
    width: 70%;
    margin: 0 auto;
    color: ivory;
  }

  
  
.toppingsList {
    list-style: none;
    padding: 0;
  }
  
  .textamt {
    width: 80%;
  }

.inputtext{
  text-decoration-style: none;
}

  
.toppingsList li {
    margin-bottom: 0.5rem;
  }
  
.toppingsListItem {
    display: flex;
    justify-content: center;
  }

.toppingsListItem div {
    float: left;
    padding-left: 0%;
  }
 
.toppingsList li:last-child {
    border-top: 1px solid #ccc;
    margin-top: 1rem;
    padding-top: 1rem;
  }

  input[type=text] {
    padding: 0;
    left: 0;
    outline: none;
    border: 2px solid #ebeeeb;
    border-color: #ebeeeb;
    background-color: ivory;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }



.spacer{
  min-width: 2%;
}

.rightsection{
  max-width: 15%;
}
.toppingsListItem label {
    vertical-align: text-bottom;
    margin-left: 0.2rem;
  }
  
.total {
    margin-top: 1rem;
  }
  
@media screen and (max-width: 600px) {
    .toppingsList,
    .total {
      width: 90%;
    }
  }

.Button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 12px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
}

.Button:hover {
  background-color: rgb(216, 87, 12);
}

i:hover{
  color: rgb(216, 87, 12);
}

.smallbutton {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 3px 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px;
  }

  .smallbutton:hover{
    background-color: rgb(216, 87, 12);
  }