.Button {
    border: none;
    color: white;
    padding: 12px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
  }


  .background {
    background-color: lawngreen;
    margin-top: 0%;
   
  }

  .innerdiv{
      margin:0%;
      padding: 0%;
  }
  h3{
      margin: 0%;
      color: ivory
  }

  .checklist{
    width: 26%;
    left: 37%;
    border: none;
    position: absolute;
    z-index: 10;
    align-self: center;
  }

  .modal{
    top: '45%';
    left: '47%';
    right: 'auto';
    bottom: 'auto';
    margin-right: '-50%';
    width: '30%';
    transform: 'translate(-40%, -10%)';
    background-color: '#3163c7'
      
  }

  .insidemodal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 1000px) {

    .checklist{
      width: 66%;
      top: 15%;
      left: 17%;
      border: none;
      position: absolute;
      z-index: 10;
      align-self: center;
    }
  
  }
  