.modal {
    font-size: 12px;
    background-color: #3163c7;
    color: ivory;
  }
  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
  }
  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }
  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }


   .modal .actions button{
    background-color: #4CAF50; /* Green */
    border: none;
    color: ivory;
    padding: 12px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
  }

  .uploadFile{
    display: inline-block;
  }

  @media only screen and (max-width: 1000px) {

    .modal {
      font-size: 12px;
      background-color: #3163c7;
      color: ivory;
      width: 100%;
    }

    .popup-overlay {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
    }

    .popup-content {
      width: max-content;
    }
  }
  
