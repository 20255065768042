.navigatorleft{
    background-color: #072969;
    text-align: right;
    border: none;
    margin: 0%;
    padding: 1%;
    width: 30.5%;
    display: inline;
    

}

.navigatorright{
    background-color: #072969;
    text-align: right;
    border: none;
    margin: 0%;
    padding-left: 1%;
    width: 40%;
    display: inline;
    

}

.navigatorimg{
    background-color: #072969;
    text-align: right;
    border: none;
    margin: 0%;
    padding-bottom: 1%;
    width: 30%;
    display: inline;
    

}

.navigatorcontainer nav .navigatorimg{
    width: 5%;
    margin: 0%;
    padding: 0.5%;
}

.navigatorcontainer nav{
    margin: 0%;
    padding: 0.5%;
}

.Button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 1px 1px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
  }

  .Button:hover {
    background-color: rgb(216, 87, 12);
  }

.navigatorcontainer ul {
    list-style: none;
    border: none;
    display: inline;
    padding: 1px;
}

.navigatorcontainer ul li {
    display: inline-block;
    padding-left: 1%;
    color: ivory;
    list-style: none;
}

.navigatorimg{
    display: inline-block;
}

.navigatorimg img{
    width: 6%;
    border: none;
    position: absolute;
    z-index: 10;
}

.bmwdiv{
    height: inherit;
    width: 1%;
    padding-left: 1%;
}

.bmwlogo{
    width: 2.7%;
    border: none;
    z-index: 10;
    position: absolute;
}


@media only screen and (max-width: 1000px) {

    .navigatorimg {
        margin: 5%;
        display: block;
        z-index: 5;
    }

    .navigatorimg img{
        width: 12%;
        margin-left: 6%;
        border: none;
        position: absolute;
        z-index: 10;
        margin-top: 4%;
        
    }
    .bmwlogo{
        margin: 1.5%;
        width: 8%;
        border: none;
        z-index: 10;
        position: absolute;
    }

    .Button{
        margin-top: 4%;
        width:max-content;
    }
    .navigatorleft{
        background-color: #072969;
        text-align: right;
        border: none;
        margin: 0%;
        padding: 1%;
        width: 25.5%;
        display: inline-block;
        
    
    }
    .navigatorright{
        background-color: #072969;
        text-align: right;
        border: none;
        margin: 0%;
        padding-left: 1%;
        width: 30%;
        display: inline;   
    }
   

}


.navigatorcontainer{
    width: 100%;
    padding: 0%;
    border: 0px;
    margin: 0%;
    display: block;
    background-color: #072969;
}